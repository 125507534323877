import { createFormData } from "./fetch";

export const EVENTS_ENDPOINT = "/events/record";

type Event = {
    target?: string;
    type: string;
    page?: string;
    details?: Record<string, any>;
    extraBodyContent?: Record<string, any>;
};

export async function logEngagementEvent({
    target,
    type,
    page,
    details,
    extraBodyContent = {},
}: Event) {
    try {
        const urlParams = new URLSearchParams(window?.location?.search);
        const user = urlParams.get("user");
        const link = urlParams.get("link");

        const body = createFormData({
            csrfmiddlewaretoken: cedar.serverDataGlobal.csrf_token,
            event_type: type,
            current_page: page ?? window?.location?.pathname,
            ...(target && { event_target: target }),
            ...(details && { event_details: JSON.stringify(details) }),
            ...(user && { user }),
            ...(link && { link }),
            ...extraBodyContent,
        });

        const res = await fetch(EVENTS_ENDPOINT, {
            method: "POST",
            body,
        });
        if (!res.ok) {
            throw new Error(res.statusText);
        }
    } catch (error) {
        // https://cedar-jira.atlassian.net/browse/CED-65205
        console.warn("Failed to record engagement event.", error);
    }
}
