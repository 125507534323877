import { NAVBAR_HEIGHT, ICONS_CONTAINER_HEIGHT } from "widgets/NavBar/NavBar";
import { TOP_BAR_HEIGHT } from "widgets/NavBar/TopBar/TopBar";
import { BREAKPOINTS } from "design_system/src/hooks/useResizeObserver";

/**
 * Calculate the padding-top of the main content based on the Header height.
 * Header includes the banner and the nav bar.
 *
 * @param bannerElement The optional banner element that is used to calculate the padding-top of the main content.
 *
 */
export function setMainPaddingTop(bannerElement?: Element) {
    const bannerHeight = bannerElement instanceof Element ? bannerElement.clientHeight : 0;
    const providerViewingBanner = document.getElementById("provider-viewing-bar");
    const nonProdOutboundCommsEnabledBanner = document.getElementById(
        "non-prod-outbound-comms-enabled-banner",
    );
    const nonProdNoPhiBanner = document.getElementById("non-prod-no-phi-banner");
    const providerViewingBannerHeight = providerViewingBanner
        ? providerViewingBanner.clientHeight
        : 0;
    const nonProdOutboundCommsEnabledBannerHeight = nonProdOutboundCommsEnabledBanner
        ? nonProdOutboundCommsEnabledBanner.clientHeight
        : 0;
    const nonProdNoPhiBannerHeight = nonProdNoPhiBanner ? nonProdNoPhiBanner.clientHeight : 0;
    const nonProdBannerHeight = nonProdOutboundCommsEnabledBannerHeight || nonProdNoPhiBannerHeight; // there can only be one of these banners

    const paddingTop =
        bannerHeight +
        TOP_BAR_HEIGHT +
        (window.innerWidth < BREAKPOINTS.s768 ? ICONS_CONTAINER_HEIGHT : NAVBAR_HEIGHT) +
        nonProdBannerHeight +
        providerViewingBannerHeight;
    const mainContent = document.querySelector("main");
    mainContent?.style.setProperty("padding-top", `${paddingTop}px`);
}

/**
 * On window resize, window reload and banner close, recalculate the padding-top of the main content.
 *
 * @param bannerElement The optional banner element that is used to calculate the padding-top of the main content.
 *
 */
export function loadHeaderHeightObserver(bannerElement?: Element) {
    // Update padding-top on window load and resize
    window.addEventListener("resize", () => {
        setMainPaddingTop(bannerElement);
    });
    window.addEventListener("load", () => {
        setMainPaddingTop(bannerElement);
    });

    // Update padding-top when banner is closed
    if (bannerElement instanceof Element) {
        const bannerObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                if (entry.target === bannerElement) {
                    setMainPaddingTop(bannerElement);
                }
            }
        });
        bannerObserver.observe(bannerElement);
    }
}
