"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useResizeObserver = exports.BREAKPOINTS = void 0;
var _react = require("react");
// Matches tailwind config
const BREAKPOINTS = {
  s375: 375,
  s576: 576,
  s768: 768,
  s992: 992,
  s1200: 1200
};

/**
 * Custom react hook to measure the element's size or window size if the element is omitted.
 * @param ref the element to measure
 * @returns
 */
exports.BREAKPOINTS = BREAKPOINTS;
const useResizeObserver = ref => {
  const [size, setSize] = (0, _react.useState)([0, 0]);
  (0, _react.useEffect)(() => {
    const getSize = () => {
      const [width, height] = size;
      const [newWidth, newHeight] = ref !== null && ref !== void 0 && ref.current ? [ref.current.clientWidth, ref.current.clientHeight] : [window.innerWidth, window.innerHeight];

      // https://stackoverflow.com/questions/17328742/mobile-chrome-fires-resize-event-on-scroll
      if (newWidth === width && newHeight === height) {
        return;
      }
      setSize([newWidth, newHeight]);
    };
    getSize();
    window.addEventListener("resize", getSize);
    return () => window.removeEventListener("resize", getSize);
  }, [size]);
  return size;
};
exports.useResizeObserver = useResizeObserver;