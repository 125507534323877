import { RefObject, useEffect, useState } from "react";

// Matches tailwind config
export const BREAKPOINTS = {
    s375: 375,
    s576: 576,
    s768: 768,
    s992: 992,
    s1200: 1200,
};

/**
 * Custom react hook to measure the element's size or window size if the element is omitted.
 * @param ref the element to measure
 * @returns
 */
export const useResizeObserver = <T extends HTMLElement = HTMLElement>(ref?: RefObject<T>) => {
    const [size, setSize] = useState([0, 0]);
    useEffect(() => {
        const getSize = () => {
            const [width, height] = size;
            const [newWidth, newHeight] = ref?.current
                ? [ref.current.clientWidth, ref.current.clientHeight]
                : [window.innerWidth, window.innerHeight];

            // https://stackoverflow.com/questions/17328742/mobile-chrome-fires-resize-event-on-scroll
            if (newWidth === width && newHeight === height) {
                return;
            }

            setSize([newWidth, newHeight]);
        };
        getSize();
        window.addEventListener("resize", getSize);
        return () => window.removeEventListener("resize", getSize);
    }, [size]);
    return size;
};
