"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usePrevious = void 0;
var _react = require("react");
// https://gist.github.com/jeongtae/896be1318c4b74b726262ba8d269aa9f

const usePrevious = value => {
  const ref = (0, _react.useRef)();
  (0, _react.useEffect)(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};
exports.usePrevious = usePrevious;