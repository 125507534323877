"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useScrollDirection = void 0;
var _lodash = require("lodash");
var _react = require("react");
const useScrollDirection = function () {
  let {
    enabled = true,
    throttleMs = 100,
    scrollUpTimeoutMs = 300
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const lastScrollY = (0, _react.useRef)(0);
  const scrollUpTimeoutId = (0, _react.useRef)(0);
  const [isScrolling, setIsScrolling] = (0, _react.useState)(false);
  const [isScrollingDown, setIsScrollingDown] = (0, _react.useState)(false);
  (0, _react.useEffect)(() => {
    const handleScroll = (0, _lodash.throttle)(() => {
      if (!enabled) {
        setIsScrolling(false);
        setIsScrollingDown(false);
        return;
      }
      if (window.scrollY > 0) {
        setIsScrolling(true);
      } else {
        setIsScrolling(false);
      }
      if (lastScrollY.current < window.scrollY && window.scrollY > 0) {
        if (scrollUpTimeoutId.current) {
          window.clearTimeout(scrollUpTimeoutId.current);
          scrollUpTimeoutId.current = 0;
        }
        setIsScrollingDown(true);
      } else if (!scrollUpTimeoutId.current) {
        scrollUpTimeoutId.current = window.setTimeout(() => {
          setIsScrollingDown(false);
        }, scrollUpTimeoutMs);
      }
      lastScrollY.current = window.scrollY;
    }, throttleMs);
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
      if (scrollUpTimeoutId.current) {
        window.clearTimeout(scrollUpTimeoutId.current);
        scrollUpTimeoutId.current = 0;
      }
    };
  }, [enabled]);
  return {
    isScrolling,
    isScrollingDown
  };
};
exports.useScrollDirection = useScrollDirection;