"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useOverlay = useOverlay;
var _react = _interopRequireDefault(require("react"));
var _react2 = require("@floating-ui/react");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function useOverlay() {
  let {
    initialOpen = false,
    placement = "bottom",
    modal = false,
    offset: offsetOption = 5,
    flip: flipOption = {
      crossAxis: placement.includes("-"),
      fallbackAxisSideDirection: "start",
      padding: 5
    },
    open: controlledOpen,
    onOpenChange: setControlledOpen,
    trigger = "click",
    role,
    focusContent = false
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const [uncontrolledOpen, setUncontrolledOpen] = _react.default.useState(initialOpen);
  const [labelId, setLabelId] = _react.default.useState();
  const [descriptionId, setDescriptionId] = _react.default.useState();
  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;
  const data = (0, _react2.useFloating)({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: _react2.autoUpdate,
    middleware: [(0, _react2.offset)(offsetOption), (0, _react2.flip)(flipOption), (0, _react2.shift)({
      padding: 5
    })]
  });
  const triggers = typeof trigger === "string" ? [trigger] : trigger;
  const context = data.context;
  const ariaRole = (0, _react2.useRole)(context, {
    role
  });
  const click = (0, _react2.useClick)(context, {
    enabled: controlledOpen == null && triggers.includes("click")
  });
  const focus = (0, _react2.useFocus)(context, {
    enabled: controlledOpen == null && triggers.includes("focus")
  });
  const hover = (0, _react2.useHover)(context, {
    move: true,
    delay: 300,
    enabled: controlledOpen == null && triggers.includes("hover")
  });
  const dismiss = (0, _react2.useDismiss)(context);
  const interactions = (0, _react2.useInteractions)([click, focus, hover, dismiss, ariaRole]);
  return _react.default.useMemo(() => ({
    open,
    setOpen,
    ...interactions,
    ...data,
    modal,
    focusContent,
    labelId,
    descriptionId,
    setLabelId,
    setDescriptionId
  }), [open, setOpen, interactions, data, modal, labelId, descriptionId]);
}