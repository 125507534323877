"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSlideTransition = void 0;
var _react = require("react");
/**
 * A hook to return default slide down styles to put on the sliding parent,
 * and also observe the state of the transition.
 * Used mainly to support keyboard accessibility - we want to make sure that things
 * are `hidden` and non-keyboard-navigable iff the (close) transition has completed.
 * @param enabled If the transition observer is enabled. Will set to invisible if false.
 * @param open The trigger to start the open/close transition.
 * @param durationMs The duration of the transition in milliseconds.
 * @returns The `ref` and `styles` should be put on the parent element,
 *  which is assumed to have two children - the first one is static,
 *  and the second one is the component doing the sliding.
 *  The returned `hidden` value should be used to set the `hidden`
 *  attribute or className on the second child.
 */
const useSlideTransition = _ref => {
  let {
    enabled,
    open,
    durationMs = 300
  } = _ref;
  const ref = (0, _react.useRef)(null);
  const [isVisible, setIsVisible] = (0, _react.useState)(false);
  const onTransitionEnd = (0, _react.useCallback)(() => setIsVisible(false), []);
  (0, _react.useEffect)(() => {
    if (enabled) {
      var _ref$current;
      open ? setIsVisible(true) : (_ref$current = ref.current) === null || _ref$current === void 0 ? void 0 : _ref$current.addEventListener("transitionend", onTransitionEnd);
    } else {
      setIsVisible(false);
    }
    return () => {
      var _ref$current2;
      (_ref$current2 = ref.current) === null || _ref$current2 === void 0 ? void 0 : _ref$current2.removeEventListener("transitionend", onTransitionEnd);
    };
  }, [enabled, open, onTransitionEnd]);
  const styles = (0, _react.useMemo)(() => enabled ? {
    display: "grid",
    transition: `grid-template-rows ${durationMs}ms`,
    gridTemplateRows: `min-content ${open ? "1fr" : "0fr"}`
  } : {}, [enabled, open, durationMs]);
  return {
    ref,
    styles,
    hidden: !isVisible && !open
  };
};
exports.useSlideTransition = useSlideTransition;